import * as React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp"
import { Play } from "@styled-icons/bootstrap/Play"
import { StaticImage } from "gatsby-plugin-image"
import Fade from "react-reveal/Fade"
import ModalVideo from "react-modal-video"

import bg from "../images/bg-dark.png"

const Wrapper = styled.div`
  /* background-color: #f5faff; */
  background: url(${bg}) no-repeat center center;
  background-size: cover;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const HeroContent = styled.div`
  max-width: 400px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    font-size: 4rem;
    margin: 0 auto;
    text-align: center;
  }

  p {
    font-weight: 600;
    font-size: 1.25rem;
    text-align: center;
    margin: 0;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 4rem;
      text-align: left;
    }
    p {
      text-align: left;
    }
  }

  @media (max-width: 350px) {
    h1 {
      font-size: 3rem;
    }
  }
`

const HeroImg = styled.div`
  max-width: 500px;
`

const StyledButtonContainer = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`

const StyledButton = styled.a`
  background: #002249;
  background: -webkit-linear-gradient(to right, #002249, #0c59db);
  background: linear-gradient(to right, #002249, #0c59db);
  border-color: #002249;
  transition: 0.3s;

  &:hover {
    background: -webkit-linear-gradient(to right, #0c59db, #002249);
    background: linear-gradient(to right, #0c59db, #002249);
    border-color: #0c59db;
  }

  svg {
    fill: #ffffff;
  }

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 30px;
  }
`

const StyledButtonVideo = styled.a`
  margin-bottom: 20px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }

  svg {
    fill: #222222;
    background: #ffffff;
    padding: 2px 2px 2px 4px;
    border-radius: 100%;
  }
`

const Hero = () => {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <Wrapper>
      <StyledContainer>
        <HeroContent>
          <Fade bottom delay={500}>
            <h1>Automação Industrial</h1>
            <p>
              A ConecTimme oferece soluções eficientes em automação industrial,
              otimizando tempo de produção, melhorando a qualidade e facilitando
              operações.
            </p>
          </Fade>

          <Fade bottom delay={800}>
            <StyledButtonContainer>
              <StyledButton
                className="btn btn-primary px-4 py-2"
                href="https://wa.me/5519995938158"
              >
                <Whatsapp className="mx-2" size={20} />
                Entre em contato conosco!
              </StyledButton>
            </StyledButtonContainer>
          </Fade>

          <ModalVideo
            channel="youtube"
            youtube={{ mute: 0, autoplay: 0 }}
            isOpen={isOpen}
            videoId="mxNFpoVuoQk"
            onClose={() => setOpen(false)}
          />
          <Fade bottom delay={850}>
            <StyledButtonContainer>
              <StyledButtonVideo
                className="py-2 my-2"
                href="#"
                onClick={() => setOpen(true)}
              >
                <Play className="mx-2" size={32} />
                Assista ao Vídeo!
              </StyledButtonVideo>
            </StyledButtonContainer>
          </Fade>
        </HeroContent>

        <HeroImg>
          <StaticImage
            src="../images/banner.png"
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="tracedSVG"
            alt="Estante de aço"
          />
        </HeroImg>
      </StyledContainer>
    </Wrapper>
  )
}

export default Hero
